<template>
  <q-pull-to-refresh @refresh="refresh" class="pullRefresh">
    <div class="approvalOfContingencies">
      <DataCard
        :data="allFilters"
        :fields="fields"
        :actions="actions"
        @approve="approveContingencie"
        @justify="justifyContingencie"
        @pending="pendingContingencie"
        @reject="rejectContingencie"
        @cardClick="detailContingencie"
        @filterR="filterByRequesterName"
        @filterB="filterByBranchCode"
        filterData
      />
    </div>
    <JustifiedContingencie ref="JustifiedContingencie" />
  </q-pull-to-refresh>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import DataCard from "../../components/DataCard";
import JustifiedContingencie from "./justifiedContingencie";
import formatString from "../../helpers/formatString";

export default {
  components: {
    DataCard,
    JustifiedContingencie
  },
  data() {
    return {
      fields: [
        {
          field: "contingencies",
          label: "",
          type: "string",
          class: "text-bold"
        },
        { field: "", label: "", type: "separator" },
        {
          field: "nameApprovalFirstLevel",
          label: "",
          type: "string",
          class: "text-body2 text-italic"
        },
        {
          field: "branch",
          label: "",
          type: "string",
          class: "text-body2"
        },
        {
          field: "type",
          label: "",
          type: "string",
          class: "text-overline"
        },
        {
          field: "issueDate",
          label: "",
          type: "string",
          class: "text-overline"
        }
      ],
      labelBtnTransf: "Transferir",
      colorBtnTransf: "orange",
      eventBtnTransf: "justify",
      actions: [
        {
          label: "Aprovar",
          color: "green",
          icon: "check",
          eventEmit: "approve"
        },
        {
          label: "Rejeitar",
          color: "red",
          icon: "block",
          eventEmit: "reject"
        },
        {
          label: "Transferir",
          color: "orange",
          icon: "swap_horiz",
          eventEmit: "justify"
        }
      ],
      filterWebR: null,
      filterWebB: null,
      filterR: sessionStorage.filterWebR,
      filterB: sessionStorage.filterWebB
    };
  },
  computed: {
    ...mapState("contingencies", ["contingencies"]),

    allFilters() {
      let contingencie = this.contingencies;

      switch (sessionStorage.sortWeb) {
        case "sSmLg":
          contingencie.sort((a, b) => a.value - b.value);
          break;
        case "sLgSm":
          contingencie.sort((a, b) => b.value - a.value);
          break;
        case "sNewOld":
          contingencie.sort((a, b) => b.issueDate - a.issueDate);
          break;
        case "sOldNew":
          contingencie.sort((a, b) => a.issueDate - b.issueDate);
          break;
      }

      if (this.filterR) {
        contingencie = contingencie.filter(ctg => {
          return (
            ctg.contingencie.trim().indexOf(this.filterR.trim()) >= 0 ||
            ctg.documentNumber.trim().indexOf(this.filterR.trim()) >= 0
          );
        });
      }

      if (this.filterB) {
        if (sessionStorage.filterWebB) {
          contingencie = contingencie.filter(ctg => {
            return (
              ctg.branchCode.trim().indexOf(sessionStorage.filterWebB.trim()) >=
              0
            );
          });
        } else {
          contingencie = contingencie.filter(ctg => {
            return ctg.branchCode.trim().indexOf(this.filterB.trim()) >= 0;
          });
        }
      }

      return contingencie.map(ctg => {
        var nameRequesterFirstLevel = "";
        ctg.approvalHierarchy.forEach(lvl => {
          if (lvl.level === "01") {
            nameRequesterFirstLevel = lvl.approver;
          }
        });

        return {
          id: ctg.id,
          contingencies: `${ctg.contingencie} (${ctg.documentNumber} - ${ctg.type})`,
          branch: `${ctg.branchCode} - ${ctg.branchName}`,
          requester: ctg.requester,
          nameApprovalFirstLevel: nameRequesterFirstLevel,
          issueDate: formatString(ctg.issueDate, "date"),
          contingencie: ctg.contingencie,
          level: ctg.level,
          response: ctg.response,
          status: ctg.status
        };
      });
    }
  },
  methods: {
    ...mapActions("contingencies", [
      "getContingencies",
      "approvesRejectsJustifysContingencies"
    ]),
    ...mapMutations("shared", ["setNotification"]),
    approveContingencie(contingencie) {
      this.sendResponse(
        contingencie,
        "approved",
        "APROVAR",
        "text-green text-bold"
      );
    },
    rejectContingencie(contingencie) {
      this.sendResponse(
        contingencie,
        "rejected",
        "REJEITAR",
        "text-red text-bold"
      );
    },
    justifyContingencie(contingencie) {
      if (contingencie.response === "pending") {
        this.sendConfirmationTransfer(
          contingencie,
          "canceled",
          "TRANSFERÊNCIA DE SALDO",
          "text-blue-8 text-bold"
        );
      } else {
        this.sendResponse(
          contingencie,
          "pending",
          "TRANSFERIR",
          "text-orange text-bold"
        );
      }
    },
    sendResponse(request, response, text, classMessage) {
      this.$q
        .dialog({
          title: `Confirmação`,
          message: `Deseja realmente <span class="${classMessage}">${text}</span> a Contingência ${request.contingencie}?`,
          html: true,
          ok: {
            push: true,
            color: "green",
            label: "Sim"
          },
          cancel: {
            push: true,
            color: "red",
            label: "Não"
          },
          persistent: true
        })
        .onOk(async () => {
          if (response !== "pending" && request.level === "01") {
            this.$refs.JustifiedContingencie.open(
              request,
              response,
              text,
              classMessage
            );
          } else {
            try {
              await this.approvesRejectsJustifysContingencies({
                request,
                response,
                reason: ""
              });
              this.setNotification({
                message:
                  request.level === "01"
                    ? "Aguardando a realização da TRANSFERÊNCIA no Protheus."
                    : "Resposta enviada com sucesso.",
                color: request.level === "01" ? "orange" : "green",
                position: "top"
              });
              this.getContingencies();
            } catch (error) {
              this.setNotification({
                message: error.message,
                color: "red",
                position: "top"
              });
            }
          }
        });
    },
    sendConfirmationTransfer(request, response, text, classMessage) {
      this.$q
        .dialog({
          title: `Confirmação`,
          message: `A <span class="${classMessage}">${text}</span> referente a Contingência ${request.contingencie} já foi finalizada no Protheus?`,
          html: true,
          ok: {
            push: true,
            color: "green",
            label: "Sim"
          },
          cancel: {
            push: true,
            color: "red",
            label: "Não"
          },
          persistent: true
        })
        .onOk(async () => {
          try {
            await this.approvesRejectsJustifysContingencies({
              request,
              response,
              reason: ""
            });
            this.setNotification({
              message: "Resposta enviada com sucesso.",
              color: "green",
              position: "top"
            });
            this.getContingencies();
          } catch (error) {
            this.setNotification({
              message: error.message,
              color: "red",
              position: "top"
            });
          }
        });
    },
    detailContingencie(contingencieId) {
      this.$router.push({
        name: "contingencieDetail",
        params: { contingencieId }
      });
    },
    filterByRequesterName(filterR) {
      this.filterR = filterR;
    },
    filterByBranchCode(filterB) {
      if (sessionStorage.filterWebB && !filterB) {
        this.filterB = sessionStorage.filterWebB;
      } else {
        this.filterB = filterB;
        sessionStorage.setItem("filterWebB", filterB);
      }
    },
    async refresh(done) {
      await this.getContingencies();
      done();
    }
  },
  created() {
    this.getContingencies();
  }
};
</script>

<style lang="scss">
.approvalOfContingencies {
  width: 100%;
}
</style>
